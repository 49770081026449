.demo-lesson-button {
    width: 250px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--yellow);
    border: none;
    color: #fff;
    border-radius: 40px;
    font-size: 16px;
    font-family: "Travels-Medium";
    cursor: pointer;
    transition: .2s;
}

.demo-lesson-button:focus {
    outline: none;
}

.demo-lesson-button:hover {
    box-shadow: 0 0 10px 3px var(--yellow);
}

.demo-lesson-button__icon {
    transform: rotate(-45deg);
    font-size: 20px;
    margin-left: 10px;
}


@media screen and (max-width: 992px) {
    .demo-lesson-button {
        font-size: 15px;
    }
}


@media screen and (max-width: 768px) {
    .demo-lesson-button {
        height: 50px;
    }
}