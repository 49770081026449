.group-block {
    width: 49%;
    position: relative;
    height: 400px;
    display: flex;
    align-items: end;
}

.group-block__image {
    width: 300px;
    position: absolute;
    right: 0;
    bottom: 0;
}

.group-block__title-wrapper {
    text-decoration: none;
    color: #000;
    display: inline-block;
    width: 100%;
    border: 1px solid var(--blue);
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 30px;
    border-radius: 40px;
}

.group-block__text {
    font-family: "Travels-Medium";
    font-size: 20px;
}

.group-block__title {
    font-family: "Travels-Bold";
    font-size: 30px;
}


@media screen and (max-width: 1200px) {
    .group-block {
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .group-block {
        height: 300px;
    }

    .group-block__title-wrapper {
        height: 200px;
        padding: 0 20px;
    }

    .group-block__image {
        width: 250px;
    }

    .group-block__text {
        font-size: 18px;
    }

    .group-block__title {
        font-size: 25px;
    }
}

@media screen and (max-width: 480px) {
    .group-block {
        height: 250px;
    }

    .group-block__title-wrapper {
        height: 160px;
        padding: 0 20px;
    }

    .group-block__image {
        width: 200px;
    }
    .group-block__text {
        font-size: 15px;
    }

    .group-block__title {
        font-size: 20px;
    }
}