.learn-section__blocks-wrapper--display-flex {
    display: flex;
}

.learn-section__bear-block {
    width: 40%;
    background-color: var(--blue);
    background-image: url("../../images/Bear-1.webp");
    background-repeat: no-repeat;
    background-position: 0 100%;
    border-radius: 40px;
    padding: 40px;
    margin-right: 10px;
}

.learn-section__bear-block__title {
    font-size: 30px;
    font-family: "Travels-Regular";
    color: #fff;
}

.learn-section__demo-lesson__wrapper {
    width: 550px;
    height: 80px;
    border-radius: 100px;
    margin: 50px auto;
    padding: 20px 15px;
    background-color: var(--blue);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.learn-section__demo-lesson__text {
    font-family: "Travels-Medium";
    color: #fff;
    width: 45%;
    font-size: 16px;
    cursor: default;
}








@media screen and (max-width: 1200px) {
    .learn-section__bear-block__title {
        font-size: 20px;
    }

    .learn-section__demo-lesson__text {
        font-size: 14px;
    }

    .learn-section__bear-block {
        background-size: 350px;
    }
}

@media screen and (max-width: 992px) {
    .learn-section__bear-block__title {
        font-size: 18px;
    }
}

@media screen and (max-width: 768px) {
    .learn-section__demo-lesson__wrapper {
        width: 100%;
        justify-content: center;
        background-color: transparent;
        margin: 0;
    }

    .learn-section__demo-lesson__text {
        display: none;
    }

    .learn-section__blocks-wrapper--display-flex {
        flex-direction: column;
    }

    .learn-section__bear-block {
        width: 100%;
        height: 230px;
        background-position: 10% 0;
        background-size: 240px;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 540px) {
    .learn-section__bear-block {
        height: 230px;
        background-size: 220px;
    }
}


@media screen and (max-width: 480px) {
    .learn-section__bear-block {
        height: 200px;
        background-size: 200px;
    }
}

@media screen and (max-width: 360px) {
    .learn-section__bear-block {
        height: 180px;
    }
}