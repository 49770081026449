.privacy-policy-section__title {
    font-size: 24px;
    font-family: "Travels-Bold";
}

.privacy-policy-section__bread-crumb {
    font-family: "Travels-Regular";
    color: var(--blue);
}

.privacy-policy-text__wrapper {
    margin-top: 20px;
    font-family: "Travels-Medium";
}

.privacy-policy-text__wrapper ol {
    background-color: var(--blue);
    margin: 20px 0;
    list-style: none;
}

.privacy-policy-text__wrapper ol li {
    padding: 10px;
}

.privacy-policy-text__wrapper p {
    margin-top: 10px;
}


@media screen and (max-width: 480px) {
    .privacy-policy-section__title {
        font-size: 20px;
    }
}