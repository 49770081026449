.module-card {
    width: 49%;
    border: 1px solid var(--blue);
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: .2s;
}

.module-card:hover {
    box-shadow: 0 0 20px 1px var(--blue);
}

.module-card__image {
    width: 150px;
}


.module-card__content-wrapper {
    margin-top: 20px;
}

.module-card__title {
    font-family: "Travels-Bold";
    margin-bottom: 10px;
    font-size: 25px;
}

.module-card__description {
    font-family: "Travels-Medium";
    font-size: 16px;
}



@media screen and (max-width: 992px) {
    .module-card {
        width: 100%;
    }
}