.comment-card {
    position: relative;
    border: 1px solid var(--yellow);
    border-radius: 40px;
    min-width: 700px;
    margin-right: 10px;
    padding: 30px 40px;
    overflow: hidden;
    cursor: default;
}

.comment-card:nth-child(1) {
    margin-left: 12.5%;
}

.comment-card__fullname {
    font-size: 20px;
    position: relative;
    font-family: "Travels-Bold";
    margin-bottom: 20px;
    z-index: 9;
}

.comment-card__comment {
    font-size: 16px;
    position: relative;
    font-family: "Travels-Medium";
    z-index: 9;
}



@media screen and (max-width: 992px) {
    .comment-card {
        min-width: 500px;
    }
}

@media screen and (max-width: 768px) {
    .comment-card__fullname {
        font-size: 15px;
        margin-bottom: 10px;
    }

    .comment-card__comment {
        font-size: 13px;
    }
}

@media screen and (max-width: 540px) {
    .comment-card:nth-child(1) {
        margin-left: 2.5%;
    }

    .comment-card {
        min-width: 400px;
    }
}
