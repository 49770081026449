.footer {
    border-top: 1px solid #000;
}


.footer-content__wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 20px;
}


.footer-logo__wrapper {
    display: flex;
    align-items: center;
}

.footer-logo {
    width: 100px;
}

.footer-logo__text {
    font-family: "Travels-Regular";
    width: 50%;
    margin-left: 20px;
    font-size: 15px;
}

.footer-social-net__list {
    display: flex;
    align-items: center;
    list-style: none;
    margin-top: 20px;
}

.footer-social-net__item {
    margin-right: 20px;
}

.footer-social-net__link {
    font-size: 30px;
    color: var(--blue);
    opacity: .5;
    transition: .2s;

}

.footer-social-net__link:hover {
    color: var(--blue);
    opacity: 1;
}




.footer-nav {
    display: flex;
}

.footer-nav__list {
    list-style: none;
    margin-left: 50px;
}

.footer-nav__item {
    margin-bottom: 10px;
}

.footer-nav__link {
    font-size: 15px;
    font-family: "Travels-Regular";
    color: #000;
    transition: .2s;
    text-decoration: none;
}

.footer-nav__link:hover {
    color: var(--blue);
}


.copyright-block {
    border-top: 1px solid #D8D8D8;
    padding: 20px 0;
}

.copyright {
    font-size: 15px;
    color: #8E8E8E;
    opacity: .5;
    font-family: "Travels-Regular";
}







@media screen and (max-width: 1200px) {
    .footer-social-net__link {
        font-size: 20px;
    }

    .footer-social-net__item {
        margin-right: 15px;
    }

    .footer-nav {
        display: block;
    }
}

@media screen and (max-width: 992px) {

    .footer-nav__link,
    .footer-logo__text,
    .copyright {
        font-size: 14px;
    }

    .footer-logo {
        width: 70px;
    }
}

@media screen and (max-width: 768px) {
    .footer-content__wrapper {
        display: block;
    }

    .footer-nav {
        margin-top: 40px;
    }

    .footer-nav__list {
        margin-left: 0;
    }

    .footer-nav__link,
    .footer-logo__text {
        font-size: 15px;
    }

    .copyright {
        font-size: 10px;
    }
    .footer-logo__text{
        margin-left: 10px;
        width: 40%;
    }
    .footer-social-net__link {
        font-size: 25px;
    }
}


@media screen and (max-width: 480px) {

    .footer-nav__link,
    .footer-logo__text {
        font-size: 13px;
    }
}