.group-table {
    width: 100%;
    border: 1px solid var(--blue);
    border-radius: 40px;
    cursor: default;
}

.group-table-head {
    display: flex;
    justify-content: space-between;
    font-family: "Travels-Bold";
}

.group-table-head--place,
.group-table-head--residents,
.group-table-head--total {
    padding: 20px;
}

.group-table-head--place {
    width: 15%;
    border-right: 1px solid var(--blue);
}

.group-table-head--residents {
    width: 65%;
    border-right: 1px solid var(--blue);
    display: flex;
    align-items: center;
}

.group-table-head--total {
    width: 20%;
}

.group-table-head div p {
    font-size: 15px;
}

.f-a-j-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.group-table-body {
    font-family: "Travels-Medium";
}




.table-swipe-right__text {
    display: flex;
    align-items: center;
    color: var(--blue);
    font-family: "Travels-Regular";
    cursor: default;
    font-size: 15px;
    margin-bottom: 20px;
    opacity: 0;
    visibility: hidden;
}

.table-swipe-right__text i {
    margin-left: 10px;
}



@media screen and (max-width: 768px) {
    .group-table-head div p {
        font-size: 13px;
    }

    .group-table-head--total {
        width: 25%;
    }

    .group-table-head--residents {
        width: 60%;
    }

    .group-table-head--place {
        width: 15%;
    }
}


@media screen and (max-width: 480px) {
    .group-table__wrapper {
        overflow: scroll;
    }

    .group-table {
        width: 700px;
    }

    .table-swipe-right__text {
        opacity: 1;
        visibility: visible;
    }
}

@media screen and (max-width: 360px) {
    .table-swipe-right__text {
        font-size: 13px;
    }
}