.advantages-block {
    font-family: "Travels-Medium";
    width: 25%;
    padding: 20px;
    border: 1px solid transparent;
    z-index: 9;
}


.advantages-block:nth-child(-n + 4) {
    border-bottom: 1px solid var(--blue);
}

.advantages-block:nth-child(n) {
    border-right: 1px solid var(--blue);
}

.advantages-block:nth-child(4n) {
    border-right: 1px solid transparent;
}

.advantages-block__number {
    color: var(--blue);
    font-size: 50px;
    cursor: default;
}

.advantages-block__text {
    cursor: default;
    font-size: 16px;
}








@media screen and (max-width: 1200px) {
    .advantages-block {
        font-family: "Travels-Medium";
        width: 50%;
    }

    .advantages-block:nth-child(2n) {
        border-right: 1px solid transparent;
    }

    .advantages-block:nth-child(-n + 6) {
        border-bottom: 1px solid var(--blue);
    }
}


@media screen and (max-width: 992px) {
    .advantages-block {
        font-family: "Travels-Medium";
        width: 100%;
        padding: 15px 0;
    }

    .advantages-block:nth-child(n) {
        border-right: 1px solid transparent;
        border-bottom: 1px solid var(--blue);
    }

    .advantages-block:nth-last-child(-n + 3) {
        border-bottom: 1px solid transparent;
    }
}


@media screen and (max-width: 768px) {
    .advantages-block__number {
        color: var(--blue);
        font-size: 40px;
        cursor: default;
    }

    .advantages-block__text {
        cursor: default;
        font-size: 16px;
    }
}