.demo-lesson-block {
    background-color: #F6F6F6;
    width: 75%;
    margin: 130px auto;
    border-radius: 40px;
    padding: 40px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.demo-lesson-block__image {
    width: 100px;
}

.demo-lesson-block__text {
    font-size: 15px;
    width: 70%;
    margin: 0 20px;
    font-family: "Travels-Regular";
    cursor: default;
}


@media screen and (max-width: 1200px) {
    .demo-lesson-block {
        flex-direction: column;

    }

    .demo-lesson-block__text {
        font-size: 15px;
        width: 90%;
        margin: 50px;
        font-family: "Travels-Regular";
        cursor: default;
    }
}

@media screen and (max-width: 768px) {
    .demo-lesson-block__image {
        width: 80px;
    }
}

@media screen and (max-width: 540px) {
    .demo-lesson-block {
        width: 95%;
    }
}