.button {
    font-family: "Travels-Medium";
    font-size: 20px;
    color: #fff;
    background-color: var(--yellow);
    border: none;
    padding: 15px 40px;
    border-radius: 40px;
    cursor: pointer;
    transition: .2s;
}

.button:hover {
    box-shadow: 0 0 10px 3px var(--yellow);
}


@media screen and (max-width: 992px) {
    .button {
        font-size: 15px;
        padding: 10px 30px;
    }
}
