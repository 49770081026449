* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::selection {
  background-color: var(--blue);
  color: #fff;
}

::-webkit-scrollbar {
  width: 0;
}

:root {
  --yellow: #F7C90E;
  --blue: #05B9BE;

}

@font-face {
  font-family: "Travels-Bold";
  src: url("./fonts/Travels-Bold.ttf");
}

@font-face {
  font-family: "Travels-Medium";
  src: url("./fonts/Travels-Medium.ttf");
}

@font-face {
  font-family: "Travels-Regular";
  src: url("./fonts/Travels-Regular.ttf");
}

.main {
  /* margin-top: 200px; */
  margin-top: 50px;
}

.section-container {
  width: 75%;
  margin: 0 auto 150px auto;
}

.container {
  width: 75%;
  margin: 0 auto;
}

.section__title {
  font-size: 60px;
  font-family: "Travels-Bold";
  cursor: default;
  margin-bottom: 30px;
}




@media screen and (max-width: 1200px) {
  .section__title {
    font-size: 50px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 992px) {
  .section__title {
    font-size: 45px;
  }
}

@media screen and (max-width: 768px) {
  .main {
    margin-top: 100px;
  }

  .section__title {
    font-size: 40px;
  }
}

@media screen and (max-width: 540px) {
  .section__title {
    font-size: 35px;
  }

  .container {
    width: 95%;
    margin: 0 auto;
  }

  .section-container {
    width: 95%;
    margin: 0 auto 100px auto;
  }
}

@media screen and (max-width: 480px) {
  .section__title {
    font-size: 30px;
  }
}

@media screen and (max-width: 360px) {
  .section__title {
    font-size: 25px;
  }
}