.technologies-section__block-wrapper {
    position: relative;
    padding: 40px;
    border: 1px solid var(--blue);
    border-radius: 40px;
    overflow: hidden;
    display: flex;
    align-items: center;
}


.technologies-section__block {
    font-family: "Travels-Medium";
    z-index: 9;
}

.technologies-section__technologies-wrapper {
    margin-bottom: 30px;
}

.technologies-block__wrapper {
    margin: 7px 0;
}

.technologies-section__block-text {
    font-size: 20px;
    cursor: default;
}



@media screen and (max-width: 992px) {
    .technologies-section__block-text {
        font-size: 18px;
    }
}

@media screen and (max-width: 768px) {
    .technologies-section__block-text {
        font-size: 15px;
    }
}

@media screen and (max-width: 480px) {
    .technologies-section__block-wrapper {
        padding: 20px;
    }
}