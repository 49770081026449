.learn-info-block {
    position: relative;
    width: 100%;
    height: 340px;
    border: 1px solid var(--blue);
    border-radius: 40px;
    margin-bottom: 10px;
    cursor: default;
    overflow: hidden;
}

.learn-info-block:first-child {
    margin-right: 10px;
}

.learn-info-block__title {
    position: absolute;
    left: 40px;
    right: 40px;
    top: 40px;
    font-size: 30px;
    font-family: "Travels-Medium";
    z-index: 9;
}


@media screen and (max-width: 1200px) {
    .learn-info-block {
        height: 300px;
    }

    .learn-info-block__title {
        font-size: 20px;
    }
}


@media screen and (max-width: 992px) {
    .learn-info-block {
        height: 230px;
    }

    .learn-info-block__title {
        font-size: 18px;
        left: 20px;
        right: 20px;
        top: 40px;
    }
}


@media screen and (max-width: 480px) {
    .learn-info-block {
        height: 200px;
    }

    .learn-info-block__title {
        font-size: 15px;
        left: 20px;
        right: 20px;
        top: 30px;
    }
}

@media screen and (max-width: 360px) {
    .learn-info-block {
        height: 180px;
    }
}