.comments-section__comments-wrapper {
    display: flex;
    width: 100%;
    overflow-x: scroll;
}

.swipe-right__text {
    display: flex;
    align-items: center;
    color: var(--blue);
    font-family: "Travels-Regular";
    cursor: default;
    margin-top: 30px;
    font-size: 20px;
}

.swipe-right__text i {
    margin-left: 10px;
}

@media screen and (max-width: 992px) {
    .swipe-right__text {
        font-size: 18px;
        margin-top: 20px;
    }
}

@media screen and (max-width: 768px) {
    .swipe-right__text {
        font-size: 15px;
        margin-top: 10px;
    }
}

@media screen and (max-width: 360px) {
    .swipe-right__text {
        font-size: 13px;
    }
}