.modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    transition: .3s;
}

.modal-wrapper--active {
    visibility: visible;
    opacity: 1;
}

.modal-bg {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #000000cc;
    cursor: pointer;
}

.modal {
    background-color: #ffffff;
    border-radius: 30px;
    width: 500px;
    height: 260px;
    margin: 0 20px;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}


.modal-form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.modal-input {
    font-family: "Travels-Medium";
    margin-bottom: 10px;
    border-radius: 100px;
    border: 1px solid var(--blue);
    padding: 10px;
}

.modal-input:focus {
    outline: none;
}

.modal-form button {
    font-family: "Travels-Medium";
    color: #fff;
    background-color: var(--blue);
    border: none;
    border-radius: 100px;
    padding: 10px;
    cursor: pointer;
}

.input-empty {
    border: 1px solid #ff0000;
}



.modal__success-message {
    font-size: 18px;
    text-align: center;
    font-family: "Travels-Medium";
}


.modal-privacy-policy {
    font-family: "Travels-Medium";
    font-size: 10px;
    display: flex;
    align-items: flex-start;
    margin: 10px 0;
}

.modal-privacy-policy p {
    padding: 0 10px;
}


.input-empty-warning {
    color: red;
}