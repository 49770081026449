.technologies-block {
    display: inline-block;
    font-size: 20px;
    margin: 5px 0;
    padding: 10px 40px;
    border-radius: 40px;
    color: #fff;
    cursor: default;
    transition: .2s;
}

.technologies-block:nth-child(n) {
    margin-right: 5px;
}


@media screen and (max-width: 992px) {
    .technologies-block {
        font-size: 18px;
    }
}

@media screen and (max-width: 768px) {
    .technologies-block {
        font-size: 15px;
    }
}

@media screen and (max-width: 480px) {
    .technologies-block {
        padding: 10px 25px;
    }
}

@media screen and (max-width: 360px) {
    .technologies-block {
        padding: 10px 20px;
    }
}