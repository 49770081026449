.circle {
    position: absolute;
    top: 0;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    opacity: 0;
    visibility: hidden;
    transform: translate(-50%, -50%);
    transition: visibility .5s, opacity .5s;

}

.circle--active {
    opacity: 1;
    visibility: visible;
}