.intro-section__subtitle {
    font-size: 20px;
    font-family: "Travels-Medium";
    width: 50%;
    margin: 50px 0 20px 0;
}


@media screen and (max-width: 992px) {
    .intro-section__subtitle {
        font-size: 15px;
        width: 70%;
        margin: 30px 0 20px 0;
    }
}

@media screen and (max-width: 540px) {
    .intro-section__subtitle {
        font-size: 15px;
        width: 100%;
    }
}