.school-advantages-section__advantages-blocks__wrapper {
    position: relative;
    border-radius: 40px;
    border: 1px solid var(--blue);
    display: flex;
    flex-wrap: wrap;
    padding: 30px;
    overflow: hidden;
}


@media screen and (max-width: 480px) {
    .school-advantages-section__advantages-blocks__wrapper {
        padding: 20px;
    }
}