.group-table-body__row {
    display: flex;
    height: 100px;
}

.group-table-body__row--place {
    width: 15%;
    border-right: 1px solid var(--blue);
    padding: 0 20px;
    border-bottom: 1px solid var(--blue);
}

.group-table-body__row--residents {
    width: 65%;
    border-right: 1px solid var(--blue);
    display: flex;
    align-items: center;
    padding: 0 20px;
    border-bottom: 1px solid var(--blue);
}

.group-table-body__row--total {
    width: 20%;
    padding: 0 20px;
    border-bottom: 1px solid var(--blue);
}



.group-table-body__row--residents img {
    border: 1px solid var(--blue);
    border-radius: 50%;
    width: 80px;
    margin-right: 20px;
}



.group-table-body__row:first-child {
    border-top: 1px solid var(--blue);
}

.group-table-body__row:last-child div {
    border-bottom: none;
}

.row-place {
    font-size: 20px;
    font-family: "Travels-Bold";
}

.row-text {
    font-size: 15px;
}








@media screen and (max-width: 992px) {
    .group-table-body__row--residents img {
        width: 70px;
        margin-right: 10px;
    }
}

@media screen and (max-width: 768px) {
    .group-table-body__row--residents img {
        width: 60px;
        margin-right: 5px;
    }

    .group-table-body__row--place {
        width: 15%;
        border-right: 1px solid var(--blue);
    }

    .group-table-body__row--residents {
        width: 60%;
        border-right: 1px solid var(--blue);
        display: flex;
        align-items: center;
    }

    .group-table-body__row--total {
        width: 25%;
    }

    .group-table-body__row--residents div {
        position: sticky;
        left: 0;
    }
}