.header {
    width: 75%;
    border-radius: 100px;
    margin: 0 auto;
    position: fixed;
    top: 10px;
    left: 0;
    right: 0;
    background-color: #eeeeee80;
    backdrop-filter: blur(20px);
    border: 1px solid #e0e0e0c0;
    box-shadow: 0 0 10px 5px #e9e9e94b;
    z-index: 999;
}

.nav {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav-list {
    display: flex;
    list-style: none;
}

.nav-item {
    margin: 0 5px;
}

.nav-link {
    white-space: nowrap;
    font-family: "Travels-Medium";
    padding: 5px 20px;
    color: #000000af;
    border: 1px solid transparent;
    border-radius: 20px;
    text-decoration: none;
    transition: .2s;
}

.nav-link:hover {
    border: 1px solid var(--blue);
    color: #fff;
    background-color: var(--blue);
}




@media screen and (max-width: 992px) {
    .nav-link {
        font-size: 14px;
    }
}

@media screen and (max-width: 768px) {
    .nav-link {
        padding: 5px 10px;
    }
}

@media screen and (max-width: 540px) {
    .header {
        width: 95%;
    }
}

@media screen and (max-width: 480px) {
    .nav-link{
        font-size: 13px;
    }
}